@import url(//db.onlinewebfonts.com/c/276a870fc85eeb726ae642943992d807?family=AL+Nevrada+Personal+Use+Only);

.coming_soon {
    width: 95%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.kiro {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 1rem;
}

.kiro_img {
    width: 6%;
}

.coming_soon_text {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.coming_soon_dude {
    font-family: 'Abril Fatface', cursive;
    letter-spacing: 5px;
    font-size: 4rem;
    color: #2c2929;
}

.middle_text {
    font-family: 'Abril Fatface', cursive;
    font-weight: lighter;
    font-size: 2.5rem;
    color: #2c2929;
    width: 40%;
    text-align: center;
}

.logo_dude_to_doodle {
    width: 25%;
}

.lucy_img {
    width: 10%;
}

.social_details {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1rem;
}

.social_icon {
    margin-left: 4%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.social_media_icon1 {
    width: 2.5rem;
    height: 1.6rem;
    margin: 0px 10px;
}

.social_media_icon2 {
    width: 2rem;
    height: 1.8rem;
    margin: 0px 10px;
}

.social_media_icon3 {
    width: 1.7rem;
    height: 1.5rem;
    margin: 0px 10px;
}

.font_change {
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    color: #696969;
}

.in_desktop_hidden {
    display: none;
}

@media (max-width:540px) {
    .coming_soon {
        width: 100%;
        margin: 0 auto;
    }

    .kiro_img {
        width: 20%;
    }

    .coming_soon_text {
        height: 40vh;
        flex-direction: column-reverse;
    }

    .coming_soon_dude {
        font-size: 2rem;
    }

    .middle_text {
        font-size: 1.5rem;
        width: 90%;
    }

    .logo_dude_to_doodle {
        width: 80%;
    }

    .lucy_mobile_center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lucy_img {
        width: 25%;
        margin-bottom: 2%;
    }

    .social_details {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .in_mobile_hidden {
        display: none !important;
    }

    .in_desktop_hidden {
        display: block !important;
        margin: 4% 0%;
    }

    .font_change {
        text-align: center;
        margin: 4% 0%;
        font-size: 4vw;
    }
}